import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import tw from "twin.macro"
import { ButtonLink, Button, ButtonA } from "../../atoms/Button"
import {
  firstLettersCapital,
  generateInventoryLink,
  normalizeSeriesNameForLink,
  parseDisclaimerBlocks,
  remove4by,
  sanitizeSeries,
  toUsd,
  vehicleCategory,
} from "../../../helpers"
import { Card } from "../../atoms/Card"
import { Vehicle } from "../../../global"
import { SimpleGallery } from "../../molecules/SimpleGallery"
import Icon, { Directions, Interior, MapPin } from "../../atoms/Icon"
import { Image } from "../../atoms/Image"
import { LocalStorageClient } from "../../../clients/LocalStorageClient"
import {
  addFavoritedVehicle,
  removeFavoritedVehicle,
  setFavoriteMessage,
} from "../../../contexts/Favorites/actions"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { Pill } from "../../atoms/Pill"
import { TabGroup } from "../../atoms/TabGroup"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import { ContactContext } from "../../../contexts/Contact"
import {
  openContactDealerModal,
  updateField,
} from "../../../contexts/Contact/actions"
import { ShareButton } from "../../atoms/ShareButton"
import { LanguageContext } from "../../../contexts/Language"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import ReactPannellum from "react-pannellum"
import { css } from "@emotion/react"
import useCustomCarouselEvent from "../../../hooks/Tealium/useCustomCarouselEvent"
import { LocationContext } from "../../../contexts/Location"
import moment from "moment"
import { HeaderContext } from "../../../contexts/Header"
import { Link } from "../../atoms/Link"
import useXSeriesNameData from "../../../hooks/useXSeriesNameData"
import { motion, useAnimation } from "framer-motion"
import { Tooltip } from "../../atoms/Tooltip"

const InventoryResultCard: React.FC<{
  vehicle: Vehicle
  cardRef?: React.MutableRefObject<any>
  index: number
  location?: string
  removeRecentlyViewedVehicle?: (vehicle: Vehicle) => void
}> = ({
  vehicle,
  cardRef = null,
  index,
  location,
  removeRecentlyViewedVehicle,
}) => {
  const {
    year,
    series,
    grade,
    msrp,
    vin,
    available,
    vehicleEligibility,
    modelNumber,
    color,
  } = vehicle
  const accessories = vehicle?.accessories?.filter(
    (accy: {
      consumerVisibility: any
      ila: number
      dealerVisibility: number
    }) =>
      accy.consumerVisibility ||
      (accy.ila === 1 && accy.dealerVisibility === 1),
  )
  const [favoritesState, dispatch] = useContext(FavoritesContext)
  const [_contactState, contactDispatch] = useContext(ContactContext)

  const { setOpen, setToggleMenu } = useContext(HeaderContext)

  const [isFavorited, setIsFavorited] = useState(false)
  const [selectedImageView, setSelectedImageView] = useState("exterior")
  const [state, modalDispatch] = useContext(DisclaimersContext)
  const { _, language } = useContext(LanguageContext)
  const [{ dealers }] = useContext(LocationContext)
  const xseriesSeriesNames = useXSeriesNameData()
  const inventoryLinkOptions = {
    series: sanitizeSeries(series),
    modelNumber: modelNumber.toString().toLowerCase(),
    year: year,
  }
  const params =
    typeof window !== "undefined" && new URLSearchParams(window.location.search)
  const inventoryParams = params && params?.get("s")

  let selectDealer = dealers?.find(
    (dealer: { DealerCode: string }) => dealer.DealerCode == vehicle.dealer,
  )

  const vehicleIsCurrentlyAvailable = available?.toLowerCase() === "now"
  let priceWithDealerFee = msrp
  if (selectDealer?.DealerFee) {
    priceWithDealerFee += selectDealer.DealerFee
  }

  useEffect(() => {
    if (
      !favoritesState.favoritedVehicles ||
      !Array.isArray(favoritesState.favoritedVehicles)
    )
      return

    setIsFavorited(favoritesState.favoritedVehicles.includes(vehicle.vin))
  }, [favoritesState.favoritedVehicles])

  // This is a hack for my demo for accys dept to show some concepts and get feedback
  const sanitizedSeriesName = sanitizeSeries(vehicle?.series)
  const xseriesModelLink = xseriesSeriesNames?.find(
    xseries => xseries?.name?.toLowerCase() === sanitizedSeriesName,
  )
    ? `/xseries/${normalizeSeriesNameForLink(sanitizedSeriesName)}`
    : `/xseries`
  const specialCodes = [
    { code: `XX`, link: xseriesModelLink },
    { code: `PF`, link: `/accessories/clear-paint` },
  ]

  // if any of the accessories.code starts with XX then set hasXSeries to true
  const hasXSeries = accessories?.some(accessory =>
    accessory.code.startsWith(`XX`),
  )

  function renderAccy(name: string, code: string) {
    const accy = specialCodes.find(accy =>
      accy.code.startsWith(code.substring(0, 2)),
    )
    if (accy) {
      return (
        <Link to={accy.link} css={[tw`text-gray-50 underline py-0`]}>
          {name}
        </Link>
      )
    }
    return name
  }
  // end hack

  const imageUrls = {
    interior: [],
    exterior: [],
  }

  const exteriorImages = vehicle?.images?.exterior
  if (exteriorImages.count > 0) {
    for (let i = 0; i < exteriorImages.count; i++) {
      imageUrls.exterior.push({
        image: `${exteriorImages.rootUrl}${i}.${exteriorImages.fileType}`,
      })
    }
  }

  const interiorImages = vehicle?.images?.interior
  if (interiorImages.count > 0) {
    const hasPanoramic = vehicle?.images?.panoramic.count > 0
    let i = hasPanoramic ? 1 : 0
    let limit = hasPanoramic ? interiorImages.count - 1 : interiorImages.count

    for (i; i < limit; i++) {
      imageUrls.interior.push({
        image: `${interiorImages.rootUrl}${i}.${interiorImages.fileType}`,
      })
    }
  }

  for (let i = 0; i < vehicle?.images.panoramic.count; i++) {
    imageUrls.panoramic = `${vehicle.images.panoramic.rootUrl}${i}.${vehicle.images.panoramic.fileType}`
  }

  const content = useRef(null)
  const [isExpanded, setisExpanded] = useState(false)

  const exteriorColor = color?.exterior?.hex?.split(",")

  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const { vehicleTealData } = useTealiumContext()
  const { updateContactDealerData } = useTealiumContext()
  const category = vehicleCategory(vehicle?.series)
  // Model name includes 4x designation for trucks, whereas seriesName excludes it
  const modelName = vehicle?.series
  const seriesName = remove4by(vehicle?.series)
  const dealerName = firstLettersCapital(vehicle?.dealerName)
  const hasPortPhotos = exteriorImages?.count > 4 ? "true" : "false"

  const { inventoryCarouselTealClick, handleClick, addAnalyticIds } =
    useCustomCarouselEvent(vehicle, category, location, index, hasPortPhotos)

  // Boolean allows favorite icon to display for sold + recently viewed only if it is already a favorite.
  // Option to "favorite" a sold vehicle in recently viewed is also disabled by this.
  const availableOrFavorite = vehicleEligibility || isFavorited

  // Determine if vehicle is in "recently viewed"
  const recentlyViewed = LocalStorageClient?.read("recentlyViewed")
  const isRecentlyViewed: boolean = recentlyViewed?.includes(vin)

  const controls = useAnimation()

  const cardVariants = {
    visible: (i: number) => ({
      opacity: 1,
      transition: {
        delay: i * 0.275,
      },
    }),
    hidden: { opacity: 0 },
  }

  const [currentView, setCurrentView] = useState("exterior")

  const handleFavoriteClick = () => {
    const newState = !isFavorited

    if (newState) {
      dispatch(addFavoritedVehicle(vin))
      dispatch(setFavoriteMessage("inventory"))
    } else {
      dispatch(removeFavoritedVehicle(vin))
    }
    setIsFavorited(newState)

    trackTealEvent({
      favorite_click: "favorite_click",
      vehicle_model: modelName,
      vehicle_model_exterior_color: color?.exterior?.name,
      vehicle_year: year,
      vehicle_interior_color: color?.interior?.name,
      vehicle_trim: grade,
      vehicle_trim_id: modelNumber,
      vehicle_vin: vin,
      vehicle_sale_price: `$${toUsd(msrp)}`,
      allocated_dealer_name: dealerName,
      allocated_dealer_code: vehicle.dealer,
      vehicle_segment_vehicle_page: category,
      vehicle_model_truncated: seriesName,
      has_port_photos: hasPortPhotos,

      vehicle_category: newState ? "Vehicle" : "Unfavorite Vehicle",
    })
  }

  return (
    <article
      aria-label="Inventory Results Card"
      css={[tw`shadow-4 rounded-lg relative mb-6 lg:(mb-0 max-w-[520px])`]}
      ref={cardRef}
    >
      {exteriorImages.count > 0 && (
        <div
          css={[
            tw`absolute bg-gray-100 rounded-full top-0 h-[210px] w-full blur-2xl`,
          ]}
        />
      )}
      <div
        css={[
          tw`relative rounded-t-lg overflow-hidden max-h-[280px]!`,
          exteriorImages.count == 0 &&
            tw`bg-gray-50 flex items-center justify-center`,
        ]}
        id={`panorama: ${index}`}
      >
        {exteriorImages.count == 0 && (
          <span css={[tw`text-gray-400 font-bold w-full text-center`]}>
            {_("No Images Available")}
          </span>
        )}
        {selectedImageView === "exterior" && (
          <SimpleGallery
            inventory
            inventoryData={vehicle}
            slides={imageUrls.exterior}
            navigation={exteriorImages.count > 1}
            indicators={false}
            wrapperStyles={tw`h-[210px]`}
            chevronColorProp={"gray-900"}
            chevronPosition={"inside"}
            showImagePosition={true}
            vehicleEligibility={vehicleEligibility}
            location={location}
            imageType="exterior"
            vehiclePosition={index}
            showProgressBar={true}
            showImageCount={false}
          />
        )}
        {selectedImageView === "interior" && (
          <SimpleGallery
            inventory
            inventoryData={vehicle}
            slides={imageUrls.interior}
            navigation={interiorImages.count > 1}
            indicators={false}
            wrapperStyles={tw`h-80`}
            chevronColorProp={"gray-900"}
            chevronPosition={"inside"}
            showImagePosition={true}
            location={location}
            imageType="interior"
            vehiclePosition={index}
            showProgressBar={true}
          />
        )}
        {selectedImageView === "panorama" && (
          <ReactPannellum
            id={index?.toString()}
            sceneId="firstScene"
            imageSource={imageUrls.panoramic}
            config={{
              autoRotate: -2,
              autoLoad: true,
            }}
            style={{ width: "100%", height: "320px" }}
            onPanoramaLoaded={() => {
              const el = ReactPannellum.getContainer()
              el && el.addEventListener("mousedown", handleClick)
              addAnalyticIds(el)
            }}
          />
        )}
      </div>
      <Card css={[tw`bg-white relative overflow-visible rounded-t-none`]}>
        <div css={[tw`text-xs relative`]}>
          <div css={[tw`absolute right-0 -mt-24 z-50`]}>
            <TabGroup>
              {({ activeTab, changeTab }) => (
                <>
                  <div className="group" css={[tw`text-center`]}>
                    <Button
                      css={[tw`p-0! m-0 shadow-none hover:(shadow-3)`]}
                      //selected={true}
                      //alignment="center"
                      //index={index}
                      onClick={() => {
                        if (
                          currentView === "exterior" &&
                          vehicleEligibility &&
                          interiorImages.count > 0
                        ) {
                          changeTab(1)
                          setSelectedImageView(
                            imageUrls?.panoramic?.length > 0
                              ? "panorama"
                              : "interior",
                          )
                          inventoryCarouselTealClick(
                            location !== "inventory"
                              ? `${location}:Interior`
                              : "Interior",
                          )
                          setCurrentView("interior")
                        } else if (
                          currentView === "interior" &&
                          vehicleEligibility &&
                          exteriorImages.count > 0
                        ) {
                          changeTab(0)
                          setSelectedImageView("exterior")
                          inventoryCarouselTealClick(
                            location !== "inventory"
                              ? `Exterior`
                              : `${location}:Exterior`,
                          )
                          setCurrentView("exterior")
                        }
                      }}
                      analytics-id={`${currentView}:${location}:${index + 1}`}
                    >
                      {/* Render exterior color if interior is selected */}
                      {currentView === "interior" && exteriorColor && (
                        <div
                          className="group"
                          css={[tw`relative flex justify-center items-center`]}
                        >
                          <Icon.ZoomOut
                            css={[
                              tw`hidden absolute h-5 w-5 justify-center items-center group-hover:(flex)`,
                            ]}
                          />
                          <span
                            css={[
                              tw`h-[50px] w-[50px] rounded-full inline-block border-2`,
                              exteriorColor?.length === 1 &&
                                `background-color: #${exteriorColor[0]}`,
                              exteriorColor?.length === 2 &&
                                `background: linear-gradient(45deg, #${exteriorColor[0]} 0, #${exteriorColor[0]} 50%, #${exteriorColor[1]} 50%, #${exteriorColor[1]} 100%)`,
                            ]}
                          ></span>
                        </div>
                      )}
                      {currentView === "exterior" &&
                        vehicle.color.interior.imageUrl && (
                          <div
                            className="group"
                            css={[
                              tw`relative flex justify-center items-center`,
                            ]}
                          >
                            <Icon.ZoomIn
                              css={[
                                tw`hidden absolute h-5 w-5 justify-center items-center group-hover:(flex)`,
                              ]}
                            />
                            <Image
                              src={vehicle.color.interior.imageUrl}
                              alt={vehicle.color.interior.name}
                              css={[
                                tw`h-[50px] w-[50px] inline-block rounded-full border-2`,
                              ]}
                            />
                          </div>
                        )}
                    </Button>
                    <Tooltip
                      fromInventoryCardSwatch
                      color="black"
                      css={tw`hidden mt-2 group-hover:(block) hover:(block)`}
                    >
                      {currentView === "exterior" && (
                        <span css={[tw`text-left`]}>
                          Interior:{" "}
                          {parseDisclaimerBlocks(
                            vehicle.color.interior.name,
                            selection =>
                              modalDispatch(toggleDisclaimersModal(selection)),
                          )}
                        </span>
                      )}
                      {currentView === "interior" && (
                        <span css={[tw`text-right`]}>
                          Exterior:{" "}
                          {parseDisclaimerBlocks(
                            vehicle.color.exterior.name,
                            selection =>
                              modalDispatch(toggleDisclaimersModal(selection)),
                          )}
                        </span>
                      )}
                    </Tooltip>
                  </div>
                </>
              )}
            </TabGroup>
          </div>
          <div css={[tw`flex w-full justify-between items-center`]}>
            <div
              css={[
                tw`justify-start font-book text-xs bg-gray-200 rounded-full inline-flex items-center py-1 px-2 cursor-default`,
              ]}
            >
              <Icon.MatchFull css={[tw`h-3.5 w-3.5 mr-2`]} />
              {`${_("Exact Match")}`}
            </div>
            <div css={[tw`flex justify-end pb-2`]}>
              {vehicleEligibility && (
                <ShareButton
                  url={`/inventory/${vin}`}
                  title={`${year && year} ${series && series} ${
                    grade && grade
                  }`}
                  iconStyles={tw`h-5 w-5 mb-1.5 mr-3 block`}
                />
              )}

              {availableOrFavorite && (
                <button
                  onClick={handleFavoriteClick}
                  aria-label={isFavorited ? `Unfavorite` : `Favorite`}
                  anayltics-id="favorite:"
                >
                  <Icon.Heart
                    color={isFavorited ? "red-400" : "gray-900"}
                    css={[
                      tw`h-6`,
                      css`
                        filter: drop-shadow(0 3px 3px rgb(10, 22, 70, 0.15))
                          drop-shadow(0 0 1px rgb(10, 22, 70, 0.06));
                      `,
                    ]}
                    filled={isFavorited}
                  />
                </button>
              )}
            </div>
          </div>
        </div>

        <div
          css={[tw`grid grid-cols-2 grid-rows-1 auto-rows-min min-h-[200px]`]}
        >
          <div css={[tw`text-left`]}>
            <div css={tw`text-sm`}>
              {language === "en" ? (
                <>
                  <div css={[tw`font-semibold text-lg text-gray-800`]}>
                    {year}
                  </div>
                  <div css={[tw`w-full`]}>
                    <h3
                      css={[
                        tw`uppercase font-semibold text-2xl overflow-hidden`,
                        css`
                          line-height: 1.5rem !important;
                          text-overflow: ellipsis;
                        `,
                      ]}
                    >
                      {/** Fixes bad data where series name is contained in grade causing double text */}
                      {series} {series ? grade?.replace(series, "") : grade}
                    </h3>
                  </div>
                </>
              ) : (
                <>
                  <div css={[tw`w-full`]}>
                    <h3
                      css={[
                        tw`uppercase font-semibold text-2xl text-ellipsis overflow-hidden`,
                        css`
                          line-height: 1.5rem !important;
                        `,
                      ]}
                    >
                      {/** Fixes bad data where series name is contained in grade causing double text */}
                      {series} {series ? grade?.replace(series, "") : grade}
                    </h3>
                  </div>
                  <div css={[tw`font-semibold text-lg text-gray-800`]}>
                    {year}
                  </div>
                </>
              )}

              {/* TODO figure out where this badge should go -- putting it here for demo purposes also wont be hardcode sanity address for the badge */}
              {hasXSeries && (
                <Image
                  src={
                    "https://cdn.sanity.io/images/90qxor39/staging/72def1077d483ad780601d2dbfe8cf85f6cc901b-335x158.png?w=335&h=158&auto=format"
                  }
                  alt={""}
                  objectFit={"contain"}
                  css={[tw`max-w-[65px] inline-block`]}
                />
              )}
              <div css={[tw`uppercase text-2xl mb-2`]}>
                ${toUsd(priceWithDealerFee)}
                <span css={tw`normal-case`}>
                  {parseDisclaimerBlocks(`[total_msrp]`, code =>
                    modalDispatch(toggleDisclaimersModal(code)),
                  )}
                </span>{" "}
              </div>
              {accessories && accessories.length > 0 && (
                <motion.div
                  css={[tw`text-gray-700`]}
                  custom={2}
                  variants={cardVariants}
                  animate={controls}
                  whileInView="visible"
                  initial={{ opacity: 0 }}
                  viewport={{ once: true }}
                  className="group"
                >
                  <div css={tw`relative mt-4`}>
                    <div
                      css={[
                        tw`font-semibold text-xs bg-gray-200 rounded-full inline-flex items-center py-1 px-2 cursor-default`,
                      ]}
                    >
                      {accessories.length} {_("Accessories")}
                      <span css={[tw`pl-3`]}>
                        <Icon.InfoInverted css={[tw`inline-block ml-2`]} />
                      </span>
                    </div>
                    <Tooltip
                      fromInventoryCard
                      color="black"
                      css={tw`hidden mt-2 group-hover:(block) hover:(block)`}
                    >
                      <ul css={[tw`list-disc list-outside ml-5 my-2`]}>
                        {accessories?.map(
                          (accessory: { code: string; name: string }) => (
                            <li css={[tw`text-left`]}>
                              {renderAccy(accessory.name, accessory.code)}
                            </li>
                          ),
                        )}
                      </ul>
                    </Tooltip>
                  </div>
                </motion.div>
              )}
            </div>
          </div>
          <div css={[tw`col-span-1 col-start-2 items-end text-right`]}>
            {vehicleEligibility ? (
              <div css={tw`mb-4`}>
                <span
                  css={[
                    tw`font-semibold text-sm block whitespace-nowrap w-full`,
                  ]}
                  className="optimizelyInventoryResultCardAvailability"
                >
                  {available?.toLowerCase() === "now"
                    ? _("Available Now")
                    : `${_("Available")} ${available}`}
                </span>
                <span css={[tw`min-h-[20px] block`]}>
                  {vehicleIsCurrentlyAvailable && selectDealer?.Name && (
                    <span css={tw`text-sm min-h-[20px]`}>
                      <Directions
                        color="red-400"
                        css={tw`h-4 mr-1 inline-block`}
                      />
                      {selectDealer.Name}
                    </span>
                  )}
                </span>
              </div>
            ) : (
              <span
                css={[
                  tw`font-semibold text-sm block mb-4 whitespace-nowrap w-full`,
                ]}
              >
                {_("SOLD")}
              </span>
            )}
            {/* ButtonA is used as 2nd option because <ButtonLink> will not reliably reload new inventory URL if user is on the same series Inventory page */}
            {vehicleEligibility ? (
              <ButtonLink
                to={`/inventory/${vin}`}
                onClick={() => {
                  setOpen(null)
                  setToggleMenu(false)
                }}
                primary
                css={[
                  tw`mb-2 text-xs px-0 mr-0 inline-grid whitespace-nowrap w-full`,
                  tw`lg:mr-0`,
                ]}
                state={{ params: inventoryParams }}
                className="optimizelyInventoryResultCardViewDetails"
              >
                {_("View Details")}
              </ButtonLink>
            ) : (
              <ButtonA
                href={
                  language === "es"
                    ? `/es${generateInventoryLink(inventoryLinkOptions)}`
                    : `${generateInventoryLink(inventoryLinkOptions)}`
                }
                primary
                alt="View similar vehicles"
                css={[
                  tw`w-full mb-2 text-xs px-0 mr-0 inline-grid whitespace-nowrap`,
                  tw`lg:mr-0`,
                ]}
              >
                {_("View Similar")}
              </ButtonA>
            )}
            <Button
              secondary
              title={
                !vehicleEligibility
                  ? `Remove from ${isFavorited ? "Favorites" : ""}${
                      isFavorited && isRecentlyViewed ? " and " : ""
                    }${isRecentlyViewed ? "Recently Viewed" : ""}.`
                  : null
              }
              css={[tw`w-full text-xs !px-2 ml-0`]}
              onClick={() => {
                if (vehicleEligibility) {
                  contactDispatch(updateField("comments", " "))
                  contactDispatch(
                    openContactDealerModal(
                      vehicleIsCurrentlyAvailable && selectDealer
                        ? selectDealer
                        : null,
                      vehicle,
                      "New Inventory Availability",
                      "CheckAvailability",
                      vehicleIsCurrentlyAvailable && selectDealer
                        ? true
                        : false,
                    ),
                  )
                  trackTealEvent({
                    tealium_event: "contact_form_nav",
                    dropdown_selected: "Contact Dealer",
                    contact_question: "Check Availability",
                    vehicle_model: modelName,
                    vehicle_model_truncated: seriesName,
                    vehicle_exterior_color: vehicle.color.exterior.name,
                    vehicle_interior_color: vehicle.color.interior.name,
                    vehicle_trim: vehicle.grade,
                    vehicle_trim_id: vehicle.modelNumber,
                    vehicle_vin: vehicle.vin,
                    ...(_contactState.dealer?.Name && {
                      dealer_name: _contactState.dealer?.Name,
                    }),
                    ...(_contactState.dealer?.DealerCode && {
                      dealer_code: _contactState.dealer?.DealerCode,
                    }),
                    allocated_dealer_name: dealerName,
                    allocated_dealer_code: vehicle.dealer,
                    vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
                    vehicle_segment_vehicle_page: category,
                    vehicle_year: vehicle.year,
                  })
                  updateContactDealerData({
                    fromInventory: {
                      vehicle_model: modelName,
                      vehicle_model_truncated: seriesName,
                      vehicle_exterior_color: vehicle.color.exterior.name,
                      vehicle_interior_color: vehicle.color.interior.name,
                      vehicle_trim: vehicle.grade,
                      vehicle_trim_id: vehicle.modelNumber,
                      vehicle_vin: vehicle.vin,
                      vehicle_year: vehicle.year,
                      vehicle_sale_price: `$${toUsd(vehicle.msrp)}`,
                      vehicle_segment_vehicle_page: category,
                    },
                    dealerData: {
                      dealer_name: _contactState.dealer?.Name,
                      dealer_code: _contactState.dealer?.DealerCode,
                    },
                    allocatedDealer: {
                      allocated_dealer_name: dealerName,
                      allocated_dealer_code: vehicle.dealer,
                    },
                  })
                } else if (removeRecentlyViewedVehicle) {
                  // If recently viewed, remove option should remove from recently viewed only
                  LocalStorageClient.delete("recentlyViewed", vin)
                  // delete from recently viewed state
                  removeRecentlyViewedVehicle(vehicle)
                  // If vehicle is sold, remove vehicle from favorites
                  dispatch(removeFavoritedVehicle(vin))
                } else {
                  // "For Inventory Results Card in favorites drop down, setRecentlyViewedVins is not available or needed."
                  // If vehicle is sold, remove vehicle from favorites
                  dispatch(removeFavoritedVehicle(vin))
                  // If recently viewed, remove option should remove from recently viewed only
                  LocalStorageClient.delete("recentlyViewed", vin)
                }
              }}
              analytics-id={
                vehicleEligibility &&
                `check availability:${location}:${index + 1}`
              }
              className="optimizelyInventoryResultCardCheckAvailability"
            >
              {vehicleEligibility ? _("Check Availability") : _("Remove")}
            </Button>
          </div>
        </div>
      </Card>
    </article>
  )
}

export default InventoryResultCard
