import React from "react"
import { IconProps } from "./Icon.d"
import { processIconColor } from "../../../helpers"

/**
 * Compare icon
 *
 * @param {IconProps} props
 * @param {TWColor|string} props.color - Fill color of the icon
 * @param {boolean} props.filled - Switches between filled and icon
 *
 * @returns <svg />
 */
const Compare: React.FC<IconProps> = ({
  color,
  filled,
  className,
  ...remainingProps
}) => {
  const colorString = processIconColor(color)

  return (
    <svg viewBox="0 0 22 21.47" className={className} {...remainingProps}>
      <g
        stroke={color ? colorString : "currentColor"}
        strokeWidth="1.245"
        fill={filled ? (color ? colorString : "currentColor") : "none"}
        fillRule="evenodd"
      >
        <path d="m5,.76l7,6.01-7,5.99" />
        <path d="m12,6.76H0" />
        <path d="m17,8.76l-6,6.01,6,5.99" />
        <path d="m11,14.76h11" />
      </g>
    </svg>
  )
}

export default Compare
